import * as THREE from 'three'


// basic three.js component example

export default class HoloModel extends THREE.Group {
  constructor(webgl, options = {}) {
    super(options)
    // these can be used also in other methods
    this.webgl = webgl
    this.options = options

    let modelParams = {
      "path": 'assets/hologram.sxrweb',
      "useAlpha": true,
      "useMetalRoughness": true,
      "occlusionCulling": true,
      "equirecEnvMap": 'env.jpg',
      "doubleSidedMaterials": false,
      "quality": 1.0,
      "shadows": true
    };

    const self = this;

    this.hologram = createHologram(
      'https://stream.senseofspace.io/sense/deepraj/hologram.sxrweb',
      // 'https://stream.senseofspace.io/sense/gm-test/hologram.sxrweb',
      () => {
        const mesh = hologram.getMesh();
        self.add(mesh);
        mesh.rotation.set(0, -1.6, 0);
        mesh.traverse((node) => {
          if (node.isMesh) {
            node.castShadow = true;
            node.material.toneMapped = false;
          }
        });
      }, () => {
        // Loaded but not ready to play
      }, () => {
        console.log('Loaded')
        document.getElementById('app').classList.remove('hidden');
        document.getElementById('playText').classList.remove('hidden');
        document.getElementById('loadingText').classList.add('hidden');
        const play = document.getElementById('play');
        play.addEventListener('click', () => {
          play.classList.add('transparent')
          this.hologram.playHologram(true)
        })
        
      }, 
      1

    )

    window.hologram = this.hologram;
    

    // this.add(this.object3d)
  }


  update(dt, time) {
    this.hologram.updateHologram();
  }

}
