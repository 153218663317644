import * as THREE from 'three'
import glsl from 'glslify'
import assets from '../utils/AssetManager'
import { wireValue, wireUniform } from '../utils/Controls'
import { addUniforms, customizeVertexShader } from '../utils/customizeShader'



// preload the rock2 model
const rock2Key = assets.queue({
  url: 'assets/models/mossy_rock.glb',
  type: 'gltf',
})



export default class Rock2 extends THREE.Group {
  constructor(webgl, options = {}) {
    super(options)
    this.webgl = webgl
    this.options = options

    const rock2Gltf = assets.get(rock2Key)
    const rock2 = rock2Gltf.scene;

    rock2.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.material.envMap = webgl.scene.environment;
      }
    })
  

  
    rock2.scale.multiplyScalar(0.02)
    rock2.position.set(1.3, 2, -0.4);

    this.add(rock2)

    this.rock2 = rock2;

    // set the background as the hdr

  }

  update(dt, time) {
    this.rock2.rotation.y += dt * 0.5
    this.rock2.rotation.z += dt * 0.3
    this.rock2.position.y = 2 +  Math.sin(time * 0.5) * 0.1
    this.rotation.y += dt * 0.3;
  }
}
