import * as THREE from 'three'
import glsl from 'glslify'
import assets from '../utils/AssetManager'
import { wireValue, wireUniform } from '../utils/Controls'
import { addUniforms, customizeVertexShader } from '../utils/customizeShader'



// preload the fig model
const figKey = assets.queue({
  url: 'assets/models/fig_tree.glb',
  type: 'gltf',
})



export default class Fig extends THREE.Group {
  constructor(webgl, options = {}) {
    super(options)
    this.webgl = webgl
    this.options = options

    const figGltf = assets.get(figKey)
    const fig = figGltf.scene;

    fig.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
      }
    })
  

  
    fig.scale.multiplyScalar(0.8)
    fig.position.set(-0.2, 0, -0.3);

    this.add(fig)

    // set the background as the hdr

  }
}
