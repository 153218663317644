import * as THREE from 'three'
import glsl from 'glslify'
import assets from '../utils/AssetManager'
import { wireValue, wireUniform } from '../utils/Controls'
import { addUniforms, customizeVertexShader } from '../utils/customizeShader'



// preload the goodMeasureStudio model
const goodMeasureStudioKey = assets.queue({
  url: 'assets/models/goodmeasure.glb',
  type: 'gltf',
})



export default class GoodMeasureStudio extends THREE.Group {
  constructor(webgl, options = {}) {
    super(options)
    this.webgl = webgl
    this.options = options

    const goodMeasureStudioGltf = assets.get(goodMeasureStudioKey)
    const goodMeasureStudio = goodMeasureStudioGltf.scene;

    goodMeasureStudio.traverse((child) => {
      if (child.isMesh) {
        console.log(child.material);
        child.receiveShadow = true;
      }
    })
  

    window.goodMeasureStudio = goodMeasureStudio;
  
    goodMeasureStudio.scale.multiplyScalar(1)

    this.add(goodMeasureStudio)

    // set the background as the hdr

  }
}
