import * as THREE from 'three'
import glsl from 'glslify'
import assets from '../utils/AssetManager'
import { wireValue, wireUniform } from '../utils/Controls'
import { addUniforms, customizeVertexShader } from '../utils/customizeShader'



// preload the rock1 model
const rock1Key = assets.queue({
  url: 'assets/models/boulder.glb',
  type: 'gltf',
})



export default class Rock1 extends THREE.Group {
  constructor(webgl, options = {}) {
    super(options)
    this.webgl = webgl
    this.options = options

    const rock1Gltf = assets.get(rock1Key)
    const rock1 = rock1Gltf.scene.clone()

  

  
    rock1.scale.multiplyScalar(0.08)
    rock1.position.set(-1, 0, -1);

    this.add(rock1)
    this.rock1 = rock1

    // set the background as the hdr

  }

  update(dt, time) {
    this.rock1.rotation.y += dt * 0.5
    this.rock1.rotation.z += dt * 0.3
    this.rock1.position.y = 1.5 +  Math.sin(time * 0.2) * 0.3
    this.rotation.y += dt * 0.2;
  }
}
