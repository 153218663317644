import { EffectPass, VignetteEffect } from 'postprocessing'
import WebGLApp from './utils/WebGLApp'
import assets from './utils/AssetManager'
import Suzanne from './scene/Suzanne'
import Box from './scene/Box'
import Ground from './scene/Ground'
import { addNaturalLight } from './scene/lights'
import { addScreenshotButton, addRecordButton } from './scene/screenshot-record-buttons'
import { Vector3 } from 'three'
import HoloModel from './scene/HoloModel'
import Sphere from './scene/Sphere'
import Rock1 from './scene/Rock1'
import Rock2 from './scene/Rock2'
import Fig from './scene/Fig'
import Flower from './scene/Flower'
import Mushroom from './scene/Mushroom'
import Grass from './scene/Grass'
import GoodMeasureStudio from './scene/GoodMeasureStudio'

// true if the url has the `?debug` parameter, otherwise false
window.DEBUG = window.location.search.includes('debug')

// grab our canvas
const canvas = document.querySelector('#app')

// setup the WebGLRenderer
const webgl = new WebGLApp({
  canvas,
  // set the scene background color
  background: '#111',
  backgroundAlpha: 1,
  // enable postprocessing
  postprocessing: true,
  // show the fps counter from stats.js
  showFps: window.DEBUG,
  // enable OrbitControls
  orbitControls: true,
  // Add the controls pane inputs
  controls: {
    roughness: 0.5,
    movement: {
      speed: {
        value: 1.5,
        max: 100,
        scale: 'exp',
      },
      frequency: { value: 0.5, max: 5 },
      amplitude: { value: 0.7, max: 2 },
    },
  },
  hideControls: !window.DEBUG,
  // enable cannon-es
  // world: new CANNON.World(),
})


// hide canvas
webgl.canvas.style.visibility = 'hidden'

const loadingText = document.getElementById('loadingText')
let loadingTextIndex = 0;
const dots = [
  '', '.', '..', '...'
]


  setInterval(() => {

    loadingTextIndex = (loadingTextIndex + 1) % 4;
    loadingText.innerHTML = 'LOADING' + dots[loadingTextIndex]
  }, 330)


// load any queued assets
assets.load({ renderer: webgl.renderer }).then(() => {

  // add any "WebGL components" here...
  // append them to the scene so you can
  // use them from other components easily
  // webgl.scene.suzanne = new Suzanne(webgl)
  // webgl.scene.add(webgl.scene.suzanne)

  webgl.renderer.shadowMap.enabled = true;
  webgl.renderer.physicallyCorrectLights = false;
  webgl.renderer.outputEncoding = 3000
  webgl.orbitControls.autoRotate = true;

  // webgl.renderer.gammaInput = true;
	// webgl.renderer.gammaOutput = true;
	// webgl.renderer.setClearColor( 0xff0000, 0);

  // webgl.scene.box = new Box(webgl)
  // webgl.scene.add(webgl.scene.box)

  // webgl.scene.rock1 = new Rock1(webgl);
  // webgl.scene.add(webgl.scene.rock1);

  // webgl.scene.fig = new Fig(webgl);
  // webgl.scene.add(webgl.scene.fig);

  // webgl.scene.mushroom = new Mushroom(webgl);
  // webgl.scene.add(webgl.scene.mushroom);

  // webgl.scene.flower = new Flower(webgl);
  // webgl.scene.add(webgl.scene.flower);

  // webgl.scene.grass = new Grass(webgl);
  // webgl.scene.add(webgl.scene.grass);

  // webgl.scene.rock2 = new Rock2(webgl);
  // webgl.scene.add(webgl.scene.rock2);


  // webgl.scene.sphere = new Sphere(webgl)
  // webgl.scene.add(webgl.scene.sphere)


  // webgl.scene.ground = new Ground(webgl)
  // webgl.scene.add(webgl.scene.ground)

  webgl.scene.goodMeasureStudio = new GoodMeasureStudio(webgl)
  webgl.scene.add(webgl.scene.goodMeasureStudio)

  // lights and other scene related stuff
  addNaturalLight(webgl)

  webgl.orbitControls.target.set(0, 1, 0);

  webgl.camera.position.set(0, 2, 8);
  webgl.camera.lookAt(new Vector3(0, 0.1, 0));

  // postprocessing
  // add an existing effect from the postprocessing library
  // webgl.composer.addPass(new EffectPass(webgl.camera, new VignetteEffect()))

  webgl.holoModel = new HoloModel(webgl);
  webgl.scene.add(webgl.holoModel);



  // add the save screenshot and save gif buttons
  if (window.DEBUG) {
    addScreenshotButton(webgl)
    addRecordButton(webgl)
  }

  // show canvas
  webgl.canvas.style.visibility = ''

  // start animation loop
  webgl.start()
})
