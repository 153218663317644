import * as THREE from 'three'
import glsl from 'glslify'
import assets from '../utils/AssetManager'
import { wireValue, wireUniform } from '../utils/Controls'
import { addUniforms, customizeVertexShader } from '../utils/customizeShader'



// preload the grass model
const grassKey = assets.queue({
  url: 'assets/models/tuft_of_grass.glb',
  type: 'gltf',
})



export default class Grass extends THREE.Group {
  constructor(webgl, options = {}) {
    super(options)
    this.webgl = webgl
    this.options = options

    const grassGltf = assets.get(grassKey)
    const grass = grassGltf.scene;

    grass.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.material.transparent = false;
        console.log(child.material);
      }
    })

    const grass1 = grass.clone();
  

    window.grass = grass;
  
    grass.scale.multiplyScalar(3)
    grass.position.set(-0.5, 0, 0.44);

  grass1.scale.multiplyScalar(3.8)
  grass1.position.set(0.1, 0, -0.44);
  grass1.rotation.y = 55;

    this.add(grass)
    this.add(grass1)

    // set the background as the hdr

  }
}
