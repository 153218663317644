import * as THREE from 'three'
import glsl from 'glslify'
import assets from '../utils/AssetManager'
import { wireValue, wireUniform } from '../utils/Controls'
import { addUniforms, customizeVertexShader } from '../utils/customizeShader'



// preload the mushroom model
const mushroomKey = assets.queue({
  url: 'assets/models/mushrooms.glb',
  type: 'gltf',
})



export default class Mushroom extends THREE.Group {
  constructor(webgl, options = {}) {
    super(options)
    this.webgl = webgl
    this.options = options

    const mushroomGltf = assets.get(mushroomKey)
    const mushroom = mushroomGltf.scene;

    mushroom.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
        child.material.transparent = false;
        console.log(child.material);
      }
    })
  

    window.mushroom = mushroom;
  
    mushroom.scale.multiplyScalar(0.01)
    mushroom.position.set(0.18, 0, -0.34);

    this.add(mushroom)

    // set the background as the hdr

  }
}
