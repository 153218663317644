import * as THREE from 'three'
import assets from '../utils/AssetManager'

// preload the environment map
const hdrKey = assets.queue({
  url: 'assets/ouside-afternoon-blurred-hdr.jpg',
  type: 'env-map',
})

// natural hemisphere light from
// https://threejs.org/examples/#webgl_lights_hemisphere
export function addNaturalLight(webgl) {
  const ambientLight = new THREE.AmbientLight( 0xffffff ); // soft white light
  ambientLight.intensity = 1.5;
  webgl.scene.add(ambientLight)

  const dirLight = new THREE.DirectionalLight(0xffffff, 1)
  dirLight.color.setHSL(0.1, 1, 0.95)
  dirLight.position.set(3, 5, 1)
  dirLight.position.multiplyScalar(50)
  dirLight.castShadow = true;
  webgl.scene.add(dirLight)


  const envMap = assets.get(hdrKey)
  webgl.scene.environment = envMap;


  // var ambient = new THREE.AmbientLight( 0xffffff, 1 );
	// webgl.scene.add( ambient );

	// var directionalLight = new THREE.DirectionalLight( 0xffffff, 0.4 );
	// directionalLight.position.set(5, 1 , -1);
	// webgl.scene.add( directionalLight );

  webgl.scene.background = new THREE.Color(0xffffff)
}
